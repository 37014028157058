<template>
  <div class="lp-sec__programme">
    <div class="container lp-pg__container">
      <div class="row align-items-start lp-pg__Learning">
        <div class="col-md-3 col-6 column-align lp-pg__section">
          <h6 class="lp-pg__digit">100+</h6>
          <div class="lp-pg__heart-image">
            <img
              src="@/assets/images/landing/icons/heart.svg"
              v-for="it in [1, 2, 3, 4, 5]"
              :key="it"
            />
          </div>
          <p class="lp-pg__content">{{ $t("search.tab.learning_programs") }}</p>
        </div>
        <div class="col-md-3 col-6 column-align lp-pg__section">
          <h6 class="lp-pg__digit">120+</h6>
          <div class="lp-pg__heart-image">
            <img
              src="@/assets/images/landing/icons/star.svg"
              v-for="it in [1, 2, 3, 4, 5]"
              :key="it"
            />
          </div>
          <p class="lp-pg__content">
            {{
              $t("landing_page.learning_section.competencies_credentials.label")
            }}
          </p>
        </div>
        <div class="col-md-6 lp-pg__trusted">
          <div class="lp-pg__trusted--content">
            {{ $t("landing_page.learning_section.main_heading") }}
          </div>
          <div class="lp-pg__courses mb-2">
            {{ $t("landing_page.learning_section.courses_from.label") }}
          </div>
          <div class="lp-pg__images">
            <!-- <img
              src="@/assets/images/landing/icons/openwho.svg"
              class="img-fluid mr-4 pt-1"
            /> -->
            <img
              :src="
                require(`@/assets/images/logo/whoa-logo-black-${logoLanguage}.svg`)
              "
              width="auto"
              height="48"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import getLogo from "@/core/mixins/getLogo";

export default {
  mixins: [getLogo]
};
</script>

<style scoped lang="scss">
.lp-pg__Learning {
  margin-top: 128px;
  margin-bottom: 128px;
}
.lp-sec__programme {
  background-color: #ebf2fd;
  padding: 0.1px 40px;
  margin-top: $landing_page_common_margin_top;
  .lp-pg__container {
    .lp-pg__section {
      text-align: left;
      .lp-pg__digit {
        font-family: $font-family-source-sans;
        color: #191c1d;
        font-weight: 700;
        font-size: 70px;
        line-height: 70px;
        letter-spacing: -1px;
      }
      .lp-pg__heart-image {
        img {
          padding-left: 5px;
        }
      }
      .lp-pg__content {
        font-family: $font-family-source-sans;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #191c1d;
        line-height: 28px;
      }
    }
    .lp-pg__trusted {
      text-align: left;
      .lp-pg__trusted--content {
        font-family: $font-family-source-sans;
        font-weight: 600;
        font-size: 34px;
        line-height: 45px;
        letter-spacing: -0.586207px;
        color: #191c1d;
      }
      .lp-pg__courses {
        font-family: $font-family-source-sans;
        font-weight: 400;
        font-size: 18px;
        margin-top: 7%;
        color: #7c8087;
      }
      .lp-pg__images {
        img {
          text-align: left;
        }
      }
    }
  }
}

// arabic alignment
[dir="rtl"] {
  .lp-sec__programme {
    .lp-pg__container {
      .lp-pg__section {
        text-align: right;
        .lp-pg__digit {
          text-align: right;
        }
      }
      .lp-pg__trusted {
        text-align: right;
        .lp-pg__images {
          img {
            text-align: right;
          }
        }
      }
    }
  }
}

@media screen and (width: 768px) {
  .lp-pg__images {
    display: block !important;
  }
}

@media screen and (max-width: 768px) {
  .lp-sec__programme {
    padding: 10px;
    margin-top: 64px;
    .lp-pg__container {
      padding: 0px;
      .lp-pg__section:first-child {
        margin-bottom: 5px;
      }
      .lp-pg__digit {
        font-size: 70px !important;
      }
      .lp-pg__trusted {
        text-align: center !important;
        margin: 0px;
      }
      .column-align {
        @include center;
        p {
          text-align: center;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .lp-pg__images {
    display: flex;
  }
  .lp-sec__programme {
    margin-top: 64px;
  }
}
</style>
